
import React, {useEffect, useState} from "react";

import firebase from "../firebaseConfig";
import "../styles/Login.css";
import { useNavigate } from "react-router-dom";

function Subscription() {
    const navigate = useNavigate();
    const [freePeriodUsed, setFreePeriodUsed] = useState(null);

    useEffect(() => {
        // Vérifier si l'utilisateur est déjà connecté
        const checkLogged = () => {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    const userId = user.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data();
                        setFreePeriodUsed(data.freePeriodUsed);
                        if(new Date(data.endSubscriptionDate.seconds * 1000) > new Date()){
                            navigate('/')
                        }
                    }
                } else {
                    console.log("not connected")
                }
            });
        };

        checkLogged();
    }, []);

    const addPlan = async (nbDays, useFree = false) => {
        /*
        const user = firebase.auth().currentUser;
        if (user) {
            const docRef = firebase.firestore().collection('users').doc(user.uid);
            try {
                await docRef.set(
                    {
                        endSubscriptionDate: new Date(new Date().getTime() + nbDays * 24 * 60 * 60 * 1000),
                        freePeriodUsed: useFree
                    },
                    { merge: true }
                );
                // window.location.href = "/dashboard";
            } catch (error) {
                console.log('Error updating endSubscriptionDate:', error);
                // Gérer l'erreur de mise à jour du champ
            }
        }*/
    };

    return (
        <div className="container centering">
                <div className="vertical-center">
                    <h5 className="text-center mt-3">In order to use OFD, you must choose a pricing plan</h5>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Pricing</h5>
                            <h6 style={{color:"#FF0000", textAlign:'center'}}><u><b>Note: App In development, to add a plan, contact your referent.</b></u></h6>
                            <div className={"d-flex "}>
                                <div className="card h-100 m-2">
                                    <div className="card-body flex-grow-0 p-4">
                                        <span className="badge bg-primary text-uppercase mb-2">1 Month</span>
                                        <h4 className="display-4 fw-bold card-title">$20<span
                                            className="fs-3 fw-normal text-muted">/mo +tx</span></h4>
                                    </div>
                                    <div className="card-footer d-flex flex-column flex-grow-1 justify-content-between p-4">
                                        <div>
                                            <ul className="list-unstyled">
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Lectus ut nibh quam, felis porttitor.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Ante nec venenatis etiam lacinia.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Porta suscipit netus ad ac.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Morbi praesent aptent integer at.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Nisl potenti ut auctor lobortis.</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <button className="btn btn-primary d-block w-100" type="button" onClick={() => addPlan(30)}>SELECT PLAN</button>
                                    </div>
                                </div>

                                <div className="card h-100 m-2">
                                    <div className="card-body flex-grow-0 p-4">
                                        <span className="badge bg-primary text-uppercase mb-2">3 Month</span>
                                        <h4 className="display-4 fw-bold card-title">$16<span
                                            className="fs-3 fw-normal text-muted">/mo +tx</span></h4>
                                    </div>
                                    <div className="card-footer d-flex flex-column flex-grow-1 justify-content-between p-4">
                                        <div>
                                            <ul className="list-unstyled">
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Lectus ut nibh quam, felis porttitor.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Ante nec venenatis etiam lacinia.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Porta suscipit netus ad ac.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Morbi praesent aptent integer at.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Nisl potenti ut auctor lobortis.</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <button className="btn btn-primary d-block w-100" type="button" onClick={() => addPlan(90)}>SELECT PLAN</button>
                                    </div>
                                </div>

                                <div className="card h-100 m-2">
                                    <div className="card-body flex-grow-0 p-4">
                                        <span className="badge bg-primary text-uppercase mb-2">1 Year</span>
                                        <h4 className="display-4 fw-bold card-title">$12<span
                                            className="fs-3 fw-normal text-muted">/mo +tx</span></h4>
                                    </div>
                                    <div className="card-footer d-flex flex-column flex-grow-1 justify-content-between p-4">
                                        <div>
                                            <ul className="list-unstyled">
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Lectus ut nibh quam, felis porttitor.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Ante nec venenatis etiam lacinia.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Porta suscipit netus ad ac.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Morbi praesent aptent integer at.</span>
                                                </li>
                                                <li className="d-flex mb-2">
                                                    <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                                    <span>Nisl potenti ut auctor lobortis.</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <button className="btn btn-primary d-block w-100" type="button" onClick={() => addPlan(365)}>SELECT PLAN</button>
                                    </div>
                                </div>
                            </div>
                            {
                                freePeriodUsed === false &&
                                <div className="mt-2 text-center">
                                    <button className={"btn "} onClick={() => addPlan(15, true)}>Try for free for 15 days</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default Subscription;
