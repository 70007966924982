import React, { useState, useEffect } from 'react';
import '../styles/Admin.css';
import DashBoardNavBar from "./DashboardNavbar";
import { useNavigate } from "react-router-dom";
import firebase from "../firebaseConfig";
import toast, { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';
toastConfig({ theme: 'dark' });

const Admin = ({ user }) => {
    const [userData, setUserData] = useState(null);
    const [users, setUsers] = useState([]);
    const [userToEdit, setUserToEdit] = useState(null);
    const [newSubscriptionDate, setNewSubscriptionDate] = useState('');
    const [feedbacks, setFeedbacks] = useState([]);
    const [stats, setStats] = useState({
        totalUsers: 0,
        activeSubscriptions: 0,
        expiredSubscriptions: 0,
        totalFeedbacks: 0
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }
        
        const checkAdminStatus = async () => {
            try {
                const userDoc = await firebase.firestore().collection('users').doc(user.uid).get();
                const userData = userDoc.data();
                
                if (!userData?.isAdmin) {
                    navigate('/access-denied');
                    return;
                }
                
                setIsVerified(true);
                fetchAllUsers();
                fetchFeedbacks();
            } catch (error) {
                console.error("Error checking admin status:", error);
                navigate('/access-denied');
            }
        };
        
        checkAdminStatus();
    }, [user, navigate]);

    useEffect(() => {
        const fetchFeedbacks = async () => {
            try {
                const feedbacksSnapshot = await firebase.firestore().collection('feedbacks').get();
                const feedbacksData = feedbacksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setFeedbacks(feedbacksData);
            } catch (error) {
                console.error('Error fetching feedbacks:', error);
            }
        };
        fetchFeedbacks();
        const checkPlanValidity = async () => {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    setUserData(docSnapshot.data());
                }
            } else {
                navigate('/login');
            }
        };
        checkPlanValidity();
    }, [user, navigate]);

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const usersSnapshot = await firebase.firestore().collection('users').get();
                const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(usersData);

                const now = new Date();
                const activeUsers = usersData.filter(user => {
                    const endDate = user.endSubscriptionDate?.toDate();
                    return endDate && endDate > now;
                });

                setStats({
                    totalUsers: usersData.length,
                    activeSubscriptions: activeUsers.length,
                    expiredSubscriptions: usersData.length - activeUsers.length,
                    totalFeedbacks: feedbacks.length
                });
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchAllUsers();
    }, [feedbacks]);

    const formatDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            const date = new Date(timestamp.seconds * 1000);
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return date.toLocaleString('en-US', options);
        }
        return 'Date not available';
    };

    const formatEndDate = (date) => {
        if (!date) {
            return <span className="status-no-subscription">No subscription</span>;
        }
        const endDate = new Date(date.seconds * 1000);
        const now = new Date();
        
        if (endDate < now) {
            return <span className="status-expired">{formatDate(date)}</span>;
        }
        return <span className="status-active">{formatDate(date)}</span>;
    };

    const changeUserSubscription = async () => {
        try {
            if (userToEdit && newSubscriptionDate) {
                await firebase.firestore().collection('users').doc(userToEdit.id).update({
                    endSubscriptionDate: new Date(newSubscriptionDate)
                });

                setUsers(users.map(user => {
                    if (user.id === userToEdit.id) {
                        return { ...user, endSubscriptionDate: new Date(newSubscriptionDate) };
                    }
                    return user;
                }));

                setUserToEdit(null);
                setNewSubscriptionDate('');
                window.location.reload(false);
            }
        } catch (error) {
            console.error('Error updating subscription:', error);
        }
    };

    const deleteFeedback = async (feedbackId) => {
        try {
            await firebase.firestore().collection('feedbacks').doc(feedbackId).delete();
            setFeedbacks(feedbacks.filter(feedback => feedback.id !== feedbackId));
        } catch (error) {
            console.error('Error deleting feedback:', error);
        }
    };

    const EditSubscriptionModal = () => {
        return (
            <div className="modal fade show modal-overlay" style={{ display: "block" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5>Change user subscription</h5>
                            <button
                                className="btn-close"
                                style={{ cursor: "pointer" }}
                                type="button"
                                aria-label="Close"
                                onClick={() => setUserToEdit(null)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="newSubscriptionDate">New Subscription End :</label>
                                <input
                                    type="date"
                                    id="newSubscriptionDate"
                                    className="form-control"
                                    value={newSubscriptionDate}
                                    onChange={(e) => setNewSubscriptionDate(e.target.value)}
                                />
                                <div className="d-flex justify-content-end mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={() => setUserToEdit(null)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={changeUserSubscription}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const fetchAllUsers = async () => {
        try {
            const usersSnapshot = await firebase.firestore().collection('users').get();
            const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUsers(usersData);

            const now = new Date();
            const activeUsers = usersData.filter(user => {
                const endDate = user.endSubscriptionDate?.toDate();
                return endDate && endDate > now;
            });

            setStats({
                totalUsers: usersData.length,
                activeSubscriptions: activeUsers.length,
                expiredSubscriptions: usersData.length - activeUsers.length,
                totalFeedbacks: feedbacks.length
            });
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchFeedbacks = async () => {
        try {
            const feedbacksSnapshot = await firebase.firestore().collection('feedbacks').get();
            const feedbacksData = feedbacksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setFeedbacks(feedbacksData);
        } catch (error) {
            console.error('Error fetching feedbacks:', error);
        }
    };

    return isVerified ? (
        <div className="admin-container">
            <DashBoardNavBar user={user} />
            
            <div className="admin-header">
                <h1>Admin Dashboard</h1>
                <p>Manage users and view platform statistics</p>
            </div>

            <div className="stats-grid">
                <div className="stat-card">
                    <div className="stat-number">{stats.totalUsers}</div>
                    <div className="stat-label">Total Users</div>
                </div>
                <div className="stat-card">
                    <div className="stat-number">{stats.activeSubscriptions}</div>
                    <div className="stat-label">Active Subscriptions</div>
                </div>
                <div className="stat-card">
                    <div className="stat-number">{stats.expiredSubscriptions}</div>
                    <div className="stat-label">Expired Subscriptions</div>
                </div>
                <div className="stat-card">
                    <div className="stat-number">{stats.totalFeedbacks}</div>
                    <div className="stat-label">Total Feedbacks</div>
                </div>
            </div>

            <div className="admin-section">
                <h2>User Management</h2>
                <div className="table-responsive">
                    <table className="users-table">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>User ID</th>
                                <th>Main Experience</th>
                                <th>Registration Date</th>
                                <th>Subscription End</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.id}>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td><small className="text-muted">{user.id}</small></td>
                                    <td>{user?.Goals_1?.experience_1 || 'Not specified'}</td>
                                    <td>{formatDate(user.accountCreationDate)}</td>
                                    <td>{formatEndDate(user.endSubscriptionDate)}</td>
                                    <td>
                                        <button 
                                            className="edit-button"
                                            onClick={() => {
                                                setUserToEdit(user);
                                                setNewSubscriptionDate('');
                                            }}
                                        >
                                            Edit Subscription
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="admin-section">
                <h2>User Feedbacks</h2>
                <div className="feedback-list">
                    {feedbacks.map((feedback) => (
                        <div key={feedback.id} className="card m-2">
                            <div className="card-header d-flex justify-content-between">
                                <h5>{feedback.title}</h5>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => deleteFeedback(feedback.id)}
                                >
                                    Delete Feedback
                                </button>
                            </div>
                            <div className="p-2">
                                <div>
                                    <b>Description:</b> {feedback.description}
                                </div>
                                <b>Username:</b> {feedback.userName} <u>({feedback.userId})</u>
                                <div>
                                    <b>Timestamp:</b> {formatDate(feedback.timestamp)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {userToEdit && <EditSubscriptionModal />}
        </div>
    ) : null;
};

export default Admin;